<template>
  <div>
    {{param.a}}
  </div>
</template>

<script>
export default {
  name: 'tab-base-page',
  props: {
    param: {
      type: Object,
      default: () => ({
        a: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
